import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.devArticles.article
  const { previous, next } = pageContext

  return (
    <Layout location={location} title="Home">
      <SEO title={post.title} />
      <article>
        <header>
          <h1
            style={{
              marginBottom: 0,
            }}
          >
            {post.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.readable_publish_date}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.body_html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`blog/${previous.fields.slug}`} rel="prev">
                ← {previous.article.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`blog/${next.fields.slug}`} rel="next">
                {next.article.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    devArticles(fields: { slug: { eq: $slug } }) {
      article {
        body_html
        cover_image
        tags
        title
        readable_publish_date
      }
      fields {
        slug
      }
    }
  }
`
